<template>
  <v-card>
    <v-card-title>Представьтесь, пожалуйста:</v-card-title>
    <v-card-text>
      <v-text-field label="Ваше имя" v-model="name" hint="Минимум 4 символа" />
      <v-select
        :items="audioDevices"
        v-model="selectedAudioDevice"
        label="Выберите микрофон"
        item-text="label"
        return-object
        v-if="isAudioDeviceExists && !isBlankAudioDevice"
      />
      <v-select
        :items="videoDevices"
        v-model="selectedVideoDevice"
        label="Выберите камеру"
        item-text="label"
        return-object
        v-if="
          type === 'video' &&
          false &&
          isVideoDeviceExists &&
          !isBlankVideoDevice
        "
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text :disabled="!isValid" @click="onSubmit">
        Войти
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const MIN_NAME_LENGTH = 4;
export default {
  name: "EnterForm",
  props: ["type"],
  data() {
    return {
      name: "",
      selectedAudioDevice: null,
      selectedVideoDevice: null,
      isAudioDeviceExists: true,
      isBlankAudioDevice: false,
      isVideoDeviceExists: true,
      isBlankVideoDevice: false,
      audioDevices: [],
      videoDevices: [],
    };
  },
  created() {
    this.getConnectedDevices();
  },
  computed: {
    isValid() {
      if (!this.name) return false;
      const isNameValid = this.name.trim().length >= MIN_NAME_LENGTH;
      const isAudioValid =
        this.selectedAudioDevice != null ||
        (this.isAudioDeviceExists && this.isBlankAudioDevice);
      const isVideoValid =
        this.selectedVideoDevice != null ||
        (this.isVideoDeviceExists && this.isBlankVideoDevice);
      return (
        isNameValid && isAudioValid && (this.type !== "video" || isVideoValid)
      );
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", {
        name: this.name,
        audioDevice: this.selectedAudioDevice,
        videoDevice: this.selectedVideoDevice,
      });
    },
    async getConnectedDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );

      if (audioDevices.length === 0) {
        this.isAudioDeviceExists = false;
      } else if (audioDevices.length > 0) {
        this.isAudioDeviceExists = true;
        this.isBlankAudioDevice = !audioDevices[0].deviceId;
        if (!this.isBlankAudioDevice) {
          this.audioDevices = audioDevices;
          this.selectedAudioDevice = audioDevices[0];
        }
      }

      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length === 0) {
        this.isVideoDeviceExists = false;
      } else if (videoDevices.length > 0) {
        this.isVideoDeviceExists = true;
        this.isBlankVideoDevice = false;
        if (!this.isBlankVideoDevice) {
          this.videoDevices = videoDevices;
          this.selectedVideoDevice = videoDevices[0];
        }
      }
    },
  },
};
</script>
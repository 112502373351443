<template>
  <div>
    <enter-form v-if="!me" @submit="onUserNameSubmit" :type="type" />
    <chat-room
      :type="type"
      v-else
      :me="me"
      :audio-device="audioDevice"
      :video-device="videoDevice"
    />
  </div>
</template>

<script>
import EnterForm from "./EnterForm";
import ChatRoom from "./ChatRoom";

export default {
  name: "AppExperimental",
  components: {
    EnterForm,
    ChatRoom,
  },
  props: ["type"],
  data() {
    return {
      me: null,
      audioDevice: null,
      videoDevice: null,
      members: [],
    };
  },
  methods: {
    onUserNameSubmit(value) {
      const { name, audioDevice, videoDevice } = value;
      this.audioDevice = audioDevice;
      this.videoDevice = videoDevice;
      this.me = {
        name,
      };
    },
  },
  computed: {},
};
</script>
<template>
  <v-sheet class="pl-2 pr-2">
    <v-list :value="0">
      <v-list-item-group :value="0" color="primary">
        <chat-member
          v-for="peer in peers"
          :key="peer.id"
          :peer="peer"
          :is-me="myPeer.id === peer.id"
          :muted-by-peer="roomUsers[peer.id] && roomUsers[peer.id].mutedByPeer"
          @mute-update="onMuteUpdate"
          :type="type"
        />
      </v-list-item-group>
    </v-list>
  </v-sheet>
</template>

<script>
import { io } from "socket.io-client";
import ChatMember from "./ChatMember";
import _ from "lodash";

export default {
  name: "ChatRoom",
  props: ["me", "audioDevice", "videoDevice", "type"],
  components: {
    ChatMember,
  },
  data() {
    return {
      peers: [],
      peersMap: {},
      socket: null,
      myPeer: null,
      userId: null,
      stream: null,
      mutedUsers: {},
      roomUsers: {},
    };
  },
  created() {
    this.initSocketIO();
  },
  methods: {
    initSocketIO() {
      const socketIoPath = "/api/ws";
      this.socket = io({
        path: socketIoPath,
      });

      this.socket.on("user-disconnected", (userId) => {
        if (this.peersMap[userId]) this.peersMap[userId].close();
        this.peers = this.peers.filter((peer) => peer.id !== userId);
      });
    },
    async initMyMedia() {
      const stream = await navigator.mediaDevices.getUserMedia({
        video:
          this.type === "video"
            ? { width: 640, height: 480, facingMode: "user" }
            : false,
        audio: true,
      });

      this.stream = stream;
      this.peers.push({
        muted: false,
        stream,
        id: this.userId,
        name: this.me.name,
        me: true,
      });

      this.myPeer.on("call", (call) => {
        call.answer(stream);
        call.on("stream", (userVideoStream) => {
          this.peers = [
            ...this.peers.filter((peer) => peer.id !== call.peer),
            {
              muted: false,
              mutedByPeer: !!_.get(this.roomUsers[call.peer], "mutedByPeer"),
              stream: userVideoStream,
              id: call.peer,
              me: false,
              name: _.get(this.roomUsers[call.peer], "name"),
            },
          ];
        });
        call.on("close", () => {
          this.peers = this.peers.filter((peer) => peer.id !== call.peer);
        });
      });
    },
    connectToNewUser(userId, userName) {
      console.log("connectToNewUser", userId, userName);
      const call = this.myPeer.call(userId, this.stream);
      if (call == null) return;
      call.on("stream", (userVideoStream) => {
        this.peers = [
          ...this.peers.filter((peer) => peer.id !== userId),
          {
            muted: false,
            stream: userVideoStream,
            id: userId,
            me: false,
            name: userName,
          },
        ];
      });
      call.on("close", () => {
        this.peers = this.peers.filter((peer) => peer.id !== userId);
      });

      this.peersMap[userId] = call;
    },

    onMuteUpdate(peerId, muted) {
      const peer = this.peersById[peerId];

      if (!peer) {
        return;
      }

      const isMe = peer.id === this.myPeer.id;
      if (isMe) {
        this.socket.emit("mute", this.roomId, this.myPeer.id, muted);
        peer.mutedByPeer = muted;
      } else {
        peer.muted = muted;
      }
    },
  },
  beforeDestroy() {
    this.socket.close();
    this.myPeer.disconnect();
  },
  computed: {
    roomId() {
      return `${this.type}-Команда "Свои Люди"`;
    },
    peersById() {
      return _.keyBy(this.peers, "id");
    },
  },
};
</script>